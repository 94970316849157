export default {
    props: {
        display: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        dialog: false,
    }),
    watch: {
        dialog(val) {
            if (!val) this.emitClose();
            else this.clearForm();
        },
        display(val) {
            if (val) this.dialog = true;
            else this.emitClose();
        }
    },
    methods: {
        emitClose() {
            this.dialog = false;
            this.$emit('close');
        },
    }
}